import { useEffect } from 'react'
import { scrollToElement } from './scrollToElement'

/**
 * Хук скролирование к элементу по elementId
 */
export const useScrollElement = ({ elementId }: { elementId: string }) => {
  useEffect(() => {
    if (!elementId) {
      return
    }

    scrollToElement(elementId)
  }, [elementId])
}
