export const DEFAULT_SCROLL_DELAY = 100

export const scrollToElement = (id: string): void => {
  if (!window || !document) {
    return null
  }

  const element = document.getElementById(id)

  if (!element) {
    return
  }
  const elementPosition = element.getBoundingClientRect().top + window.scrollY

  if (elementPosition) {
    setTimeout(() => {
      window.scrollTo({ top: elementPosition, behavior: 'smooth' })
    }, DEFAULT_SCROLL_DELAY)
  }
}
