import React, { useEffect } from 'react'
import Head from 'next/head'
import type { AppProps } from 'next/app'
import isPropValid from '@emotion/is-prop-valid'
import { StyleSheetManager } from 'styled-components'
import { getAssetsPath } from '@shared/lib'
import { TBeforeInstallPromptEvent, TPageProps } from './types'
import { APP_DEFAULT_TITLE, APP_DEFAULT_DESCRIPTION } from './constants'

/**
 * [Документация styled-component]{@link https://styled-components.com/docs/faqs#shouldforwardprop-is-no-longer-provided-by-default}
 * @param propName
 * @param target
 */

const shouldForwardProp = (propName: string, target) => (typeof target === 'string' ? isPropValid(propName) : true)

export const App = ({ Component, pageProps }: AppProps<TPageProps>) => {
  const {
    title = APP_DEFAULT_TITLE,
    description = APP_DEFAULT_DESCRIPTION,
    url,
    image,
    keywords,
    robots,
    ogType,
    twitterImage,
    appleItunesApp,
    googlePlayApp,
    fbAppID,
    fbImage,
  } = pageProps.meta || {}

  useEffect(() => {
    // отчлючаем дефолтное поведение Chrome (Desktop + Android) с предложением установиь приложение
    const onBeforeinstallprompt = (e: TBeforeInstallPromptEvent) => {
      e.preventDefault()
    }

    window.addEventListener('beforeinstallprompt', onBeforeinstallprompt)

    return () => {
      window.removeEventListener('beforeinstallprompt', onBeforeinstallprompt)
    }
  }, [])

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1, viewport-fit=cover" />
        <link rel="shortcut icon" href={getAssetsPath('icons/favicon.ico')} />
        <link rel="apple-touch-icon" href={getAssetsPath('icons/apple-touch-icon.png')} />
        <link rel="icon" href={getAssetsPath('icons/icon.svg')} type="image/svg+xml" />
        <link rel="manifest" href={getAssetsPath('/manifest.webmanifest')} />
        <link rel="mask-icon" href={getAssetsPath('icons/icon.svg')} />
        <meta name="msapplication-TileColor" content="036D7B" />
        <meta name="theme-color" content="#FFFDF7" />

        {robots && <meta name="robots" content={robots} />}
        {title && <title>{title}</title>}
        {description && <meta name="description" content={description} />}
        {image && <meta name="image" content={image} />}
        {url && <link rel="canonical" href={url} />}
        {keywords && <meta name="keywords" content={keywords} />}

        {url && <meta property="og:url" content={url} />}
        {title && <meta property="og:title" content={title} />}
        {description && <meta property="og:description" content={description} />}
        {(fbImage || image) && <meta property="og:image" content={fbImage || image} />}
        {ogType && <meta property="og:type" content={ogType} />}
        {fbAppID && <meta property="fb:app_id" content={fbAppID} />}

        {title && <meta name="twitter:title" content={title} />}
        {description && <meta name="twitter:description" content={description} />}
        {(twitterImage || image) && <meta name="twitter:image" content={twitterImage || image} />}

        {googlePlayApp && <meta name="google-play-app" content={googlePlayApp} />}
        {appleItunesApp && <meta name="apple-itunes-app" content={appleItunesApp} />}
      </Head>
      <StyleSheetManager shouldForwardProp={shouldForwardProp}>
        <Component {...pageProps} />
      </StyleSheetManager>
    </>
  )
}
